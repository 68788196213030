<template>
  <div>

    <HeaderPersonal />

    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form three w-container">
        <div class="item-loan-contein">
          <div class="w-layout-vflex item-loan-col">
            <div class="tittle-form profil">
              <h1 class="heading-form">Активные займы</h1>
            </div>

            <div class="w-layout-hflex item-loan-row one">
              <div class="w-layout-vflex item-loan">
                <div class="w-layout-hflex status">
                  <div class="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect width="16" height="16" rx="8" fill="#EFF1F4"></rect>
                    <path d="M8 3.19995L8 7.99994M8 10.64L8 12.8" stroke="#5B6478" stroke-opacity="0.5" stroke-width="1.6"></path>
                  </svg></div>
                  <div class="text-status" style="margin-left: 10px;">История займов отсутствует</div>
                </div>
              </div>
            </div>

<!--            <div class="w-layout-hflex item-loan-row one">-->
<!--              <div class="w-layout-vflex item-loan">-->
<!--                <div class="w-layout-hflex status">-->
<!--                  <div class="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">-->
<!--                    <rect width="16" height="16" rx="8" fill="#EFF1F4"></rect>-->
<!--                    <path d="M8 3.19995L8 7.99994M8 10.64L8 12.8" stroke="#5B6478" stroke-opacity="0.5" stroke-width="1.6"></path>-->
<!--                  </svg></div>-->
<!--                  <div class="text-status">Не погашен</div>-->
<!--                </div>-->
<!--                <div class="text-status">Заявка №325 от 3.05.2024</div>-->
<!--              </div>-->
<!--              <div class="w-layout-vflex item-loan row">-->
<!--                <div class="text-status black">Возврат: 4 Июня 2024</div>-->
<!--                <div class="text-status green">10 950 ₽</div>-->
<!--              </div>-->
<!--              <div class="w-layout-vflex item-loan last">-->
<!--                <a id="openLabel" href="#" class="button-bust low w-button">Погасить</a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="w-layout-hflex item-loan-row">-->
<!--              <div class="w-layout-vflex item-loan">-->
<!--                <div class="w-layout-hflex status">-->
<!--                  <div class="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">-->
<!--                    <rect width="16" height="16" rx="8" fill="#66B72C" fill-opacity="0.32"></rect>-->
<!--                    <path d="M4 8.5L6.5 11L12.5 5" stroke="#5F9E32" stroke-width="1.6"></path>-->
<!--                  </svg></div>-->
<!--                  <div class="text-status">Погашен</div>-->
<!--                </div>-->
<!--                <div class="text-status">Заявка №325 от 3.05.2024</div>-->
<!--              </div>-->
<!--              <div class="w-layout-vflex item-loan row">-->
<!--                <div class="text-status black">Возврат: 4 Июня 2024</div>-->
<!--                <div class="text-status green">10 950 ₽</div>-->
<!--              </div>-->
<!--              <div class="w-layout-vflex item-loan last">-->
<!--                <a id="openLabel" href="#" class="button-bust low w-button">Погасить</a>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderPersonal } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'ActiveLoans',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderPersonal
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
  created() {
  },
  computed: {
  },
  methods: {
  }

};
</script>

<style>
</style>
